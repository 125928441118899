import React from 'react'
import {membersMsalInstance} from './members-msa'
import {CommonAuthProvider} from '../../Common/Components/CommonAuthProvider'

type MemberAuthProviderProps = {
  children: React.ReactNode
}

export const MembersAuthProvider: React.VFC<MemberAuthProviderProps> = (props) =>
  <CommonAuthProvider msalInstance={membersMsalInstance}>
    {props.children}
  </CommonAuthProvider>
