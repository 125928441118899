import {CvsContract, CvsContractDataJSON} from './CvsContractDTO'
import {BucketRegisterResponseData, BucketRegisterResponseDataJSON, FreeBucketRegisterResponseData, FreeBucketRegisterResponseDataJSON} from './BucketRegisterResponseDataDTO'
import {CommonResponseJSON} from '../../Network/CommonResponse'

export type CreditCard3DSInfoJSON = CreditCard3DSInfo

export interface CreditCard3DSInfo {
  method: 'credit_card',
  redirectPageUrl: string
}

export const isCreditCard3DSResponse = (data: CreditCard3DSInfo
  | BucketRegisterResponseData
  | CvsContract
  | FreeBucketRegisterResponseData,
): data is CreditCard3DSInfo =>
  (data as CreditCard3DSInfo).method === 'credit_card'
  && (data as CreditCard3DSInfo).redirectPageUrl != undefined

type T = CommonResponseJSON<CreditCard3DSInfoJSON>
export const isCreditCard3DSResponseJSON = (response: CommonResponseJSON<CreditCard3DSInfoJSON
  | BucketRegisterResponseDataJSON
  | CvsContractDataJSON
  | FreeBucketRegisterResponseDataJSON>,
): response is T =>
  (response as T).data?.method === 'credit_card'
  && (response as T).data?.redirectPageUrl != undefined

export const fromJSON = (jsonObject: CreditCard3DSInfoJSON): CreditCard3DSInfo => {
  return {
    method: 'credit_card',
    redirectPageUrl: jsonObject.redirectPageUrl,
  }
}
