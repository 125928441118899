import {Optional} from '../../Common/TypeHelper'

export interface MembersBucketJSON {
  name: string
  bucketKey: string
  bucketMemberId: string
  signupDate: string
  nickname: Optional<string>
  stageRank?: number
  stageName?: string
}

export class MembersBucketDTO implements MembersBucketJSON {
  constructor(
    public name: string,
    public bucketKey: string,
    public bucketMemberId: string,
    public signupDate: string,
    public nickname: Optional<string>,
    public stageRank?: number,
    public stageName?: string,
  ) {
  }

  static fromJSON(jsonObject: MembersBucketJSON): MembersBucketDTO {
    return new MembersBucketDTO(
      jsonObject.name,
      jsonObject.bucketKey,
      jsonObject.bucketMemberId,
      jsonObject.signupDate,
      jsonObject.nickname,
      jsonObject.stageRank,
      jsonObject.stageName,
    )
  }
}