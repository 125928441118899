import {CommonResponseJSON} from '../../Network/CommonResponse'
import {CvsContract, CvsContractDataJSON} from './CvsContractDTO'
import {BucketMemberId, BucketMemberIdJSON} from './BucketMemberId'
import {Nullable, Optional} from '../../Common/TypeHelper'
import {CreditCard3DSInfo, CreditCard3DSInfoJSON} from './CreditCard3DSInfoDTO'

export interface BucketRegisterResponseDataJSON {
  method: 'credit_card'
  bucketMemberIdList: BucketMemberIdJSON[]
  contractDate: string
  contractEndDate: string
  nextContractDate: string
  nextContractEndDate: string
  returnPageUrl: Nullable<string>,
}

export interface BucketRegisterResponseData {
  bucketMemberIdList: BucketMemberId[]
  contractDate: string
  contractEndDate: string
  nextContractDate: string
  nextContractEndDate: string
  returnPageUrl: Optional<string>
}

export class BucketRegisterResponseDataDTO implements BucketRegisterResponseData {
  constructor(
    public bucketMemberIdList: BucketMemberId[],
    public contractDate: string,
    public contractEndDate: string,
    public nextContractDate: string,
    public nextContractEndDate: string,
    public returnPageUrl: Optional<string>,
  ) {
  }

  static fromJSON(jsonObject: BucketRegisterResponseDataJSON): BucketRegisterResponseDataDTO {
    return new BucketRegisterResponseDataDTO(
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
      jsonObject.contractDate,
      jsonObject.contractEndDate,
      jsonObject.nextContractDate,
      jsonObject.nextContractEndDate,
      jsonObject.returnPageUrl !== null ? jsonObject.returnPageUrl : undefined,
    )
  }
}

export type FreeBucketRegisterResponseDataJSON = FreeBucketRegisterResponseData

export type FreeBucketRegisterResponseData = {
  method: 'none'
  bucketMemberIdList: BucketMemberId[]
  returnPageUrl: Optional<string>
}

export const isFreeBucketResponseJSON = (
  response: CommonResponseJSON<CreditCard3DSInfoJSON
    | BucketRegisterResponseDataJSON
    | CvsContractDataJSON
    | FreeBucketRegisterResponseDataJSON>,
): response is CommonResponseJSON<FreeBucketRegisterResponseDataJSON> => {
  return response.data?.method === 'none'
}

export const isFreeBucketContract = (data: CreditCard3DSInfo
  | BucketRegisterResponseData
  | CvsContract
  | FreeBucketRegisterResponseData,
): data is FreeBucketRegisterResponseData =>
  (data as FreeBucketRegisterResponseData).method === 'none'

export class FreeBucketRegisterResponseDataDTO implements FreeBucketRegisterResponseData {
  method: 'none'
  bucketMemberIdList: BucketMemberId[]
  returnPageUrl: Optional<string>

  constructor(method: 'none', bucketMemberIdList: BucketMemberId[], returnaPageUrl: Optional<string>) {
    this.method = method
    this.bucketMemberIdList = bucketMemberIdList
    this.returnPageUrl = returnaPageUrl
  }

  static fromJSON(
    jsonObject: FreeBucketRegisterResponseDataJSON,
  ): FreeBucketRegisterResponseDataDTO {
    return new FreeBucketRegisterResponseDataDTO(
      jsonObject.method,
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
      jsonObject.returnPageUrl !== null ? jsonObject.returnPageUrl : undefined,
    )
  }
}

export const fromJsonBucketMemberIdList = (
  jsonBucketMemberIdList: BucketMemberIdJSON[],
): BucketMemberId[] => {
  return jsonBucketMemberIdList.map(jsonBucketMemberId => BucketMemberId.fromJSON(jsonBucketMemberId))
}
