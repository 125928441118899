import {Nullable, Optional} from '../../Common/TypeHelper'
import MailMagazineDTO, {MailMagazine, MailMagazineJSON} from './MailMagazineDTO'
import {PageUrlInfo, UrlListItem} from '../Models/Types'
import {ListUtility} from '../Utils/ListUtility'
import {NicknameStatus} from './BucketContract'
import {convertToNicknameStatus} from './BucketHeader'

export interface BucketInfoJSON {
  key: string
  name: string
  shopId: string
  nicknameRequirement: Optional<string>
  mailMagazineList: MailMagazineJSON[]
  urlList: UrlListItem[]
}

export interface BucketInfo {
  key: string
  name: string
  shopId: string
  nicknameRequirement: NicknameStatus
  mailMagazineList: MailMagazine[]
  logoImg: Optional<string>
  fcMyPageInfo: PageUrlInfo
}

export class BucketInfoDTO implements BucketInfo {
  constructor(
    public key: string,
    public name: string,
    public shopId: string,
    public nicknameRequirement: NicknameStatus,
    public mailMagazineList: MailMagazine[],
    public logoImg: Optional<string>,
    public fcMyPageInfo: PageUrlInfo,
  ) {
  }

  static fromJSON(jsonObject: BucketInfoJSON): Nullable<BucketInfoDTO> {
    if (!jsonObject.name || !jsonObject.mailMagazineList) {
      return null
    }

    const filteredLogoImg = ListUtility.findOne<UrlListItem>(
      jsonObject.urlList || [],
      item => item.type === 'logoImg',
    )
    const logoImgUrl: Optional<string> = filteredLogoImg?.url

    const filteredFcMyPage = ListUtility.findOne<UrlListItem>(
      jsonObject.urlList || [],
      item => item.type === 'fcMyPage',
    )

    const fcMyPageInfo: PageUrlInfo = {
      url: filteredFcMyPage?.url,
      name: filteredFcMyPage?.name ?? '',
    }

    return new BucketInfoDTO(
      jsonObject.key,
      jsonObject.name,
      jsonObject.shopId,
      convertToNicknameStatus(jsonObject.nicknameRequirement),
      jsonObject.mailMagazineList.map(item => ({...MailMagazineDTO.fromJSON(item)})),
      logoImgUrl,
      fcMyPageInfo,
    )
  }
}
