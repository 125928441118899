export type MobileCarrierStartPaymentInfoJSON = MobileCarrierStartPaymentInfo

export interface MobileCarrierStartPaymentInfo {
  startURL?: string
  accessID?: string
  token?: string
  redirectPageUrl?: string
}

export class MobileCarrierStartPaymentInfoDTO implements MobileCarrierStartPaymentInfo {
  startURL?: string
  accessID?: string
  token?: string
  redirectPageUrl?: string

  constructor(startURL?: string, accessID?: string, token?: string, redirectPageUrl?: string) {
    this.startURL = startURL
    this.accessID = accessID
    this.token = token
    this.redirectPageUrl = redirectPageUrl
  }

  static fromJSON(jsonObject: MobileCarrierStartPaymentInfoJSON): MobileCarrierStartPaymentInfoDTO {
    return new MobileCarrierStartPaymentInfoDTO(
      jsonObject.startURL,
      jsonObject.accessID,
      jsonObject.token,
      jsonObject.redirectPageUrl,
    )
  }
}
