import FetchWrapper from '../../Network/FetchWrapper'
import MemberAgreementRepo from './MemberAgreementRepo'
import AgreementDTO, {AgreementJSON} from '../DTO/AgreementDTO'
import {CommonResponseDTO, CommonResponseJSON} from '../../Network/CommonResponse'
import {Nullable} from '../../Common/TypeHelper'
import {withMembersAuth} from '../../Network/BrowserFetchWrapper'
import {DTO} from '../../Common/DtoUtilities'

class NetworkMemberAgreementRepo implements MemberAgreementRepo {
  private fetchWrapper: FetchWrapper
  private serverUrl = process.env.REACT_APP_MEMBUCKET_ADMIN_APP_SERVER_URL

  constructor(fetchWrapper: FetchWrapper) {
    this.fetchWrapper = fetchWrapper
  }

  postAgree(agreementIds: number[]): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/r/members/self/agreement_acceptance`,
      DTO.toObject({agreementIdCheckedList: agreementIds}),
      withMembersAuth(),
    )
      .then((response: CommonResponseJSON<null>) => {
        return CommonResponseDTO.from(
          response,
          CommonResponseDTO.NO_CONVERT,
        )
      })
  }

  getBySignupKey(signupKey: string): Promise<CommonResponseDTO<Nullable<AgreementDTO[]>>> {
    const url = `${this.serverUrl}/v1/m/p/agreements?signup_key=${signupKey}`
    return this.fetchWrapper.getByJson(url)
      .then((response: CommonResponseJSON<AgreementJSON[]>) => {
        return CommonResponseDTO.fromArray(
          response,
          AgreementDTO.fromJSON,
        )
      })
  }

  getByBucketKey(bucketKey: string): Promise<CommonResponseDTO<AgreementDTO[]>> {
    const url = `${this.serverUrl}/v1/m/p/agreements?bucket_key=${bucketKey}`
    return this.fetchWrapper.getByJson(url)
      .then((response: CommonResponseJSON<AgreementJSON[]>) => {
        return CommonResponseDTO.fromArray(
          response,
          AgreementDTO.fromJSON,
        )
      })
  }

  getNeedAgreements(signupKey?: string): Promise<CommonResponseDTO<Nullable<AgreementDTO[]>>> {
    const buildUrl = (signupKey?: string): string => {
      const url = new URL(`${this.serverUrl}/v1/m/r/members/self/need_agreements`)
      if (signupKey) {
        url.searchParams.append('signup_key', signupKey)
      }
      return url.toString()
    }

    return this.fetchWrapper.getByJson(
      buildUrl(signupKey),
      withMembersAuth(),
    ).then((response: CommonResponseJSON<AgreementJSON[]>) => {
      return CommonResponseDTO.fromArray(
        response,
        AgreementDTO.fromJSON,
      )
    })
  }
}

export default NetworkMemberAgreementRepo
