import {Optional} from '../../Common/TypeHelper'
import {NicknameStatus} from './BucketContract'

export type EnrollmentReasonInfo = {
  id: number,
  label: string
}

export interface BucketHeaderJSON {
  id: number
  bucketKey: string
  name: string
  signedUp?: boolean
  experiencedCampaigns?: string[]
  enrollmentReasonList?: Optional<EnrollmentReasonInfo[]>
  nicknameRequirement?: string
}

class BucketHeader {
  constructor(
    public id: number,
    public bucketKey: string,
    public name: string,
    public signedUp: boolean,
    public experiencedCampaigns: string[],
    public enrollmentReasonList: Optional<EnrollmentReasonInfo[]>,
    public nicknameRequirement: NicknameStatus,
  ) {
  }

  static fromJSON(jsonObject: BucketHeaderJSON): BucketHeader {
    return new BucketHeader(
      jsonObject.id,
      jsonObject.bucketKey,
      jsonObject.name,
      jsonObject?.signedUp ?? false,
      jsonObject?.experiencedCampaigns ?? [],
      jsonObject?.enrollmentReasonList,
      convertToNicknameStatus(jsonObject.nicknameRequirement),
    )
  }
}

export const convertToNicknameStatus = (input: Optional<string>): NicknameStatus => {
  switch (input) {
    case 'required':
    case 'optional':
      return input
    default:
      return 'unnecessary'
  }
}

export default BucketHeader
