import {BrowserCacheLocation, Configuration, SilentRequest,} from '@azure/msal-browser'
import {Nullable} from '../../Common/TypeHelper'
import {MBMemberError} from '../Common/MBMemberError'
import {acquireAccessToken, initializeMsalInstance, isAuthEnabled} from '../../Common/common-msa'

const tenantName = process.env.REACT_APP_AUTH_MSAL_MEMBERS_TENANT_NAME
const tenantId = process.env.REACT_APP_AUTH_MSAL_MEMBERS_TENANT_ID
const clientId = process.env.REACT_APP_AUTH_MSAL_MEMBERS_CLIENT_ID!
const domain = process.env.REACT_APP_AUTH_MSAL_MEMBERS_CUSTOM_DOMAIN || `${tenantName}.b2clogin.com`
const policyId = process.env.REACT_APP_AUTH_MSAL_MEMBERS_POLICY_ID

export const msalConfig: Nullable<Readonly<Configuration>> = isAuthEnabled() ? {
  auth: {
    clientId,
    authority: `https://${domain}/${tenantId}/${policyId}`,
    knownAuthorities: [domain],
    redirectUri: '/redirect',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    tokenRenewalOffsetSeconds: 60,
    // loggerOptions,
  }
} : null

export const membersMsalInstance = msalConfig && initializeMsalInstance(msalConfig)

export const authRequest: SilentRequest = {
  scopes: [`https://${tenantName}.onmicrosoft.com/${clientId}/members.api`],
}

export const acquireMembersAccessToken = acquireAccessToken(
  membersMsalInstance, authRequest, new MBMemberError('AuthError'))
