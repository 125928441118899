import {PaymentMethod} from './PaymentMethodDTO'
import {ContractMethod, ContractTerm, CvsContractInfo, MobileCarrierInfo, MobileCarrierNextInfo, MyCreditCardInfo} from '../Props/ContractInputInfo'
import {Nullable} from '../../Common/TypeHelper'
import {Contract} from '../Utils/Resources'
import {MBMemberError} from '../Common/MBMemberError'

export interface MemberContractInfoJSON {
  contractDate: string
  now: ContractInfoJson
  next: Nullable<ContractInfoJson>
  candidatePaymentMethodList: PaymentMethod[]
}

export interface ContractInfoJson {
  status: Contract.StatusType
  sourceType: Contract.SourceTypeType
  startDate: string
  endDate: string
  term: ContractTerm
  amount: number
  method: ContractMethod
  paymentMethodDetail: Nullable<PaymentMethodDetailJson>
}

type PaymentMethodDetailJson = Partial<MyCreditCardInfo
  & MobileCarrierInfo
  & MobileCarrierNextInfo
  & CvsContractInfo>

export interface ContractInfo {
  status: Contract.StatusType
  sourceType: Contract.SourceTypeType
  startDate: string
  endDate: string
  term: ContractTerm
  amount: number
  contractMethod: CreditCardMethod | MobileCarrierMethod | CvsMethod | FreeBucketMethod
}

export type NextContractInfo = ContractInfo & {
  contractMethod: CreditCardMethod | MobileCarrierNextMethod | CvsMethod | FreeBucketMethod
}

export type CreditCardMethod = {
  method: 'credit_card'
  paymentMethodDetail: MyCreditCardInfo
}

export type MobileCarrierMethod = {
  method: 'mobile_carrier'
  paymentMethodDetail: MobileCarrierInfo
}

export type MobileCarrierNextMethod = {
  method: 'mobile_carrier'
  paymentMethodDetail: MobileCarrierNextInfo
}

export type CvsMethod = {
  method: 'convenience_store'
  paymentMethodDetail: CvsContractInfo
}

export type FreeBucketMethod = {
  method: 'none',
  paymentMethodDetail: null
}

export interface MemberContractInfo {
  contractDate: string
  now: ContractInfo
  next: Nullable<NextContractInfo>
  candidatePaymentMethodList: PaymentMethod[]
}

export class MemberContractInfoDTO implements MemberContractInfo {
  contractDate: string
  now: ContractInfo
  next: Nullable<NextContractInfo>
  candidatePaymentMethodList: PaymentMethod[]

  constructor(
    contractDate: string,
    now: ContractInfo,
    next: Nullable<NextContractInfo>,
    candidatePaymentMethodList: PaymentMethod[],
  ) {
    this.contractDate = contractDate
    this.now = now
    this.next = next
    this.candidatePaymentMethodList = candidatePaymentMethodList
  }

  static fromJSON(jsonObject: MemberContractInfoJSON): MemberContractInfoDTO {
    return new MemberContractInfoDTO(
      jsonObject.contractDate,
      {
        status: jsonObject.now.status,
        sourceType: jsonObject.now.sourceType,
        startDate: jsonObject.now.startDate,
        endDate: jsonObject.now.endDate,
        term: jsonObject.now.term,
        amount: jsonObject.now.amount,
        contractMethod: getNowMethod(jsonObject.now.method, jsonObject.now.paymentMethodDetail),
    //     contractMethod: {
    //   method: 'mobile_carrier',
    //     paymentMethodDetail: {
    //   carrierKey: '',
    // }
    //     }

      },
      getNextContractInfo(jsonObject.next),
      jsonObject.candidatePaymentMethodList,
    )
  }
}

export const getNextContractInfo = (
  contractInfoJson: Nullable<ContractInfoJson>,
): Nullable<NextContractInfo> => {
  if (contractInfoJson === null) return null

  return {
    status: contractInfoJson.status,
    sourceType: contractInfoJson.sourceType,
    startDate: contractInfoJson.startDate,
    endDate: contractInfoJson.endDate,
    term: contractInfoJson.term,
    amount: contractInfoJson.amount,
    contractMethod: getNextMethod(contractInfoJson.method, contractInfoJson.paymentMethodDetail),
  }
}

const getNoneMethod = (): FreeBucketMethod => {
  return {
    method: 'none',
    paymentMethodDetail: null,
  }
}

const getCreditCardMethod = (
  paymentMethodDetail: PaymentMethodDetailJson,
): CreditCardMethod => {
  return {
    method: 'credit_card',
    paymentMethodDetail: {
      cardId: paymentMethodDetail.cardId ?? '',
      cardNumber: paymentMethodDetail.cardNumber ?? '',
      expireDate: paymentMethodDetail.expireDate ?? '',
    },
  }
}

const getCvsMethod = (
  paymentMethodDetail: PaymentMethodDetailJson,
): CvsMethod => {
  return {
    method: 'convenience_store',
    paymentMethodDetail: {
      convenienceStoreKey: paymentMethodDetail.convenienceStoreKey ?? '',
      paymentNumber: paymentMethodDetail.paymentNumber ?? null,
      paymentNumberLabel: paymentMethodDetail.paymentNumberLabel ?? null,
      confirmationNumber: paymentMethodDetail.confirmationNumber ?? null,
      confirmationNumberLabel: paymentMethodDetail.confirmationNumberLabel ?? null,
      paymentTerm: paymentMethodDetail.paymentTerm ?? null,
      paymentDetailUrl: paymentMethodDetail.paymentDetailUrl ?? null,
      isPaymentAvailableTerm: paymentMethodDetail.isPaymentAvailableTerm ?? false,
      paymentAvailableOn: paymentMethodDetail.paymentAvailableOn ?? null,
    },
  }
}

const getMethod = (
  method: ContractMethod,
  paymentMethodDetail: Nullable<PaymentMethodDetailJson>,
): CreditCardMethod | CvsMethod | FreeBucketMethod => {
  if (method === 'none' && (paymentMethodDetail === null || Object.keys(paymentMethodDetail).length === 0)) {
    return getNoneMethod()
  }

  if (paymentMethodDetail === null) {
    throw new MBMemberError('SystemError')
  }

  if (method === 'credit_card') {
    return getCreditCardMethod(paymentMethodDetail)
  }

  if (method === 'convenience_store') {
    return getCvsMethod(paymentMethodDetail)
  }

  throw new MBMemberError('SystemError')
}


export const getNowMethod = (
  method: ContractMethod,
  paymentMethodDetail: Nullable<PaymentMethodDetailJson>,
): CreditCardMethod | MobileCarrierMethod | CvsMethod | FreeBucketMethod => {
  if (method === 'mobile_carrier' && paymentMethodDetail !== null) {
    return {
      method: 'mobile_carrier',
      paymentMethodDetail: {
        carrierKey: paymentMethodDetail.carrierKey ?? '',
      },
    }
  }

  return getMethod(method, paymentMethodDetail)
}

export const getNextMethod = (
  method: ContractMethod,
  paymentMethodDetail: Nullable<PaymentMethodDetailJson>,
): CreditCardMethod | MobileCarrierNextMethod | CvsMethod | FreeBucketMethod => {
  if (method === 'mobile_carrier' && paymentMethodDetail !== null) {
    return {
      method: 'mobile_carrier',
      paymentMethodDetail: {
        carrierKey: paymentMethodDetail.carrierKey ?? '',
        isUnableToCancelContract: paymentMethodDetail.isUnableToCancelContract ?? false,
      },
    }
  }

  return getMethod(method, paymentMethodDetail)
}
