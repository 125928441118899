import {AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate} from '@azure/msal-react'
import React from 'react'
import {isAuthEnabled} from './common-msa'
import {InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest} from '@azure/msal-browser'
import {MsalAuthenticationResult} from '@azure/msal-react/dist/hooks/useMsalAuthentication'

type Props = {
  children: React.ReactNode,
  authRequest: PopupRequest | RedirectRequest | SsoSilentRequest,
  AuthError: React.ElementType<MsalAuthenticationResult>,
}

export const CommonAuth: React.VFC<Props> = (props) => {
  const {children, authRequest, AuthError} = props

  if (!isAuthEnabled()) {
    return <>{children}</>
  }

  return (
    <>
      <AuthenticatedTemplate>
        {children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={AuthError}/>
        {null}
      </UnauthenticatedTemplate>
    </>
  )
}

