import {ContractCampaignType, ContractMethod, ContractTerm} from '../Props/ContractInputInfo'

export interface PaymentMethodJSON {
  amount: number
  method: ContractMethod
  term: ContractTerm
  campaignType: ContractCampaignType
}

export interface PaymentMethod {
  amount: number
  method: ContractMethod
  term: ContractTerm
  campaignType: ContractCampaignType
}

export class PaymentMethodDTO implements PaymentMethod {
  amount: number
  method: ContractMethod
  term: ContractTerm
  campaignType: ContractCampaignType

  constructor(amount: number, method: ContractMethod, term: ContractTerm, campaignType: ContractCampaignType) {
    this.amount = amount
    this.method = method
    this.term = term
    this.campaignType = campaignType
  }

  static fromJSON(jsonObject: PaymentMethodJSON): PaymentMethodDTO {
    return new PaymentMethodDTO(
      jsonObject.amount,
      jsonObject.method,
      jsonObject.term,
      jsonObject.campaignType
    )
  }
}
