import {ContractMethod, ContractTerm, ConvenienceStoreInfo, CreditCardInfo, MobileCarrierInfo} from '../Props/ContractInputInfo'
import {BucketInfo, BucketInfoDTO, BucketInfoJSON} from './BucketInfoDTO'
import {Nullable} from '../../Common/TypeHelper'
import {MBMemberError} from '../Common/MBMemberError'

export type CancelBucketLeaveResultJSON = {
  bucketInfo: BucketInfoJSON
  term: ContractTerm
  method: 'credit_card'
  paymentMethodDetail: CreditCardInfo
} | {
  bucketInfo: BucketInfoJSON
  term: ContractTerm
  method: 'mobile_carrier'
  paymentMethodDetail: MobileCarrierInfo
} | {
  bucketInfo: BucketInfoJSON
  term: ContractTerm
  method: 'convenience_store'
  paymentMethodDetail: ConvenienceStoreInfo
}

export interface CancelBucketLeaveResult {
  bucketInfo: Nullable<BucketInfo>
  term: ContractTerm
  method: ContractMethod
  creditCard: CreditCardInfo
  mobileCarrier: MobileCarrierInfo
  convenienceStore: ConvenienceStoreInfo
}

export class CancelBucketLeaveResultDTO implements CancelBucketLeaveResult {
  constructor(
    public bucketInfo: Nullable<BucketInfoDTO>,
    public term: ContractTerm,
    public method: ContractMethod,
    public creditCard: CreditCardInfo,
    public mobileCarrier: MobileCarrierInfo,
    public convenienceStore: ConvenienceStoreInfo,
  ) {
  }

  static fromJSON(jsonObject: CancelBucketLeaveResultJSON): CancelBucketLeaveResultDTO {
    const defaultCreditCard: CreditCardInfo = {
      creditCardType: 'newCard',
      newCreditCardInfo: {
        cardNumber: '',
        expireMonth: '',
        expireYear: '',
        holderName: '',
        securityCode: '',
      },
      myCreditCardInfo: {selectedCardId: '', securityCodeMap: []},
    }

    const defaultMobileCarrierInfo: MobileCarrierInfo = {
      carrierKey: '',
    }

    const defaultConvenienceStoreInfo: ConvenienceStoreInfo = {
      convenienceStoreKey: '',
    }

    switch (jsonObject.method) {
      case 'credit_card':
        return new CancelBucketLeaveResultDTO(
          BucketInfoDTO.fromJSON(jsonObject.bucketInfo),
          jsonObject.term,
          jsonObject.method,
          jsonObject.paymentMethodDetail,
          defaultMobileCarrierInfo,
          defaultConvenienceStoreInfo,
        )
      case 'mobile_carrier':
        return new CancelBucketLeaveResultDTO(
          BucketInfoDTO.fromJSON(jsonObject.bucketInfo),
          jsonObject.term,
          jsonObject.method,
          defaultCreditCard,
          jsonObject.paymentMethodDetail,
          defaultConvenienceStoreInfo,
        )
      case 'convenience_store':
        return new CancelBucketLeaveResultDTO(
          BucketInfoDTO.fromJSON(jsonObject.bucketInfo),
          jsonObject.term,
          jsonObject.method,
          defaultCreditCard,
          defaultMobileCarrierInfo,
          jsonObject.paymentMethodDetail,
        )
      default:
        throw new MBMemberError('SystemError')
    }
  }
}
