import {MemberPreRegisterContractInfo} from '../Props/ContractInputInfo'
import {MemberPreJoinBucket} from './MemberPreJoinBucketDTO'
import {Optional} from '../../Common/TypeHelper'
import {EnrollmentReasonSelectedInfo} from '../View/Components/InputContentBlocks/EnrollmentReasonSelectContentBlock'

export type JoinPreRegisterInfoJSON = JoinPreRegisterInfo

export type JoinPreRegisterInfo = Pick<MemberPreJoinBucket,
  'signupKey' |
  'mailMagazineIdCheckedList' |
  'contractInfo' |
  'enrollmentReason' |
  'returnPageUrl'>

export class JoinPreRegisterInfoDTO implements JoinPreRegisterInfo {
  signupKey: string
  mailMagazineIdCheckedList: number[]
  contractInfo: MemberPreRegisterContractInfo
  enrollmentReason: Optional<EnrollmentReasonSelectedInfo>
  returnPageUrl: Optional<string>

  constructor(
    signupKey: string,
    mailMagazineIdCheckedList: number[],
    contractInfo: MemberPreRegisterContractInfo,
    enrollmentReason: Optional<EnrollmentReasonSelectedInfo>,
    returnPageUrl: Optional<string>,
  ) {
    this.signupKey = signupKey
    this.mailMagazineIdCheckedList = mailMagazineIdCheckedList
    this.contractInfo = contractInfo
    this.enrollmentReason = enrollmentReason
    this.returnPageUrl = returnPageUrl
  }

  static fromJSON(jsonObject: JoinPreRegisterInfoJSON): JoinPreRegisterInfoDTO {
    return new JoinPreRegisterInfoDTO(
      jsonObject.signupKey,
      jsonObject.mailMagazineIdCheckedList,
      jsonObject.contractInfo,
      jsonObject.enrollmentReason,
      jsonObject.returnPageUrl,
    )
  }
}
