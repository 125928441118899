import {ConvenienceStoreKey} from '../Props/ContractInputInfo'
import {BucketRegisterResponseData, BucketRegisterResponseDataJSON, FreeBucketRegisterResponseData, FreeBucketRegisterResponseDataJSON, fromJsonBucketMemberIdList} from './BucketRegisterResponseDataDTO'
import {CommonResponseJSON} from '../../Network/CommonResponse'
import {BucketMemberId} from './BucketMemberId'
import {Nullable, Optional} from '../../Common/TypeHelper'
import {CreditCard3DSInfo, CreditCard3DSInfoJSON} from './CreditCard3DSInfoDTO'

export type CvsContractDataJSON = CvsContract & {
  method: 'convenience_store'
  returnPageUrl: Nullable<string>,
}

export interface CvsContract {
  convenienceStoreKey: ConvenienceStoreKey,
  paymentNumber: string,
  paymentNumberLabel: string,
  confirmationNumber: string,
  confirmationNumberLabel: string,
  contractDate: string,
  contractEndDate: string,
  paymentTerm: string,
  paymentDetailUrl: string
  bucketMemberIdList: BucketMemberId[]
  returnPageUrl: Optional<string>,
}

export const isCvsContractResponseJSON = (
  response: CommonResponseJSON<CreditCard3DSInfoJSON
    | BucketRegisterResponseDataJSON
    | CvsContractDataJSON
    | FreeBucketRegisterResponseDataJSON>,
): response is CommonResponseJSON<CvsContractDataJSON> =>
  response.data?.method === 'convenience_store'

export const isCvsContract = (data: CvsContract
  | BucketRegisterResponseData
  | CreditCard3DSInfo
  | FreeBucketRegisterResponseData,
): data is CvsContract => (data as CvsContract).convenienceStoreKey !== undefined

export class CvsContractDTO implements CvsContract {
  constructor(
    public convenienceStoreKey: ConvenienceStoreKey,
    public paymentNumber: string,
    public paymentNumberLabel: string,
    public confirmationNumber: string,
    public confirmationNumberLabel: string,
    public contractDate: string,
    public contractEndDate: string,
    public paymentTerm: string,
    public paymentDetailUrl: string,
    public bucketMemberIdList: BucketMemberId[],
    public returnPageUrl: Optional<string>,
  ) {
  }

  static fromJSON(jsonObject: CvsContractDataJSON): CvsContractDTO {
    return new CvsContractDTO(
      jsonObject.convenienceStoreKey,
      jsonObject.paymentNumber,
      jsonObject.paymentNumberLabel,
      jsonObject.confirmationNumber,
      jsonObject.confirmationNumberLabel,
      jsonObject.contractDate,
      jsonObject.contractEndDate,
      jsonObject.paymentTerm,
      jsonObject.paymentDetailUrl,
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
      jsonObject.returnPageUrl !== null ? jsonObject.returnPageUrl : undefined,
    )
  }
}
