import {Address} from './MemberAddressDTO'
import {Nullable, Optional} from '../../Common/TypeHelper'

export type ShippingAddress = Address & {
  familyName: string,
  firstName: string,
  familyNameKana: string,
  firstNameKana: string,
  phone: string
}

export class MemberShippingAddressDTO implements ShippingAddress {
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  postCode: string
  addressPrefectureCode: string
  addressCity: string
  addressStreet: string
  addressBuilding: Nullable<Optional<string>>
  phone: string

  constructor(
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    postCode: string,
    addressPrefectureCode: string,
    addressCity: string,
    addressStreet: string,
    addressBuilding: Nullable<Optional<string>>,
    phone: string,
  ) {
    this.familyName = familyName
    this.firstName = firstName
    this.familyNameKana = familyNameKana
    this.firstNameKana = firstNameKana
    this.postCode = postCode
    this.addressPrefectureCode = addressPrefectureCode
    this.addressCity = addressCity
    this.addressStreet = addressStreet
    this.addressBuilding = addressBuilding
    this.phone = phone
  }
}

export type ShippingAddressInfoJSON = ShippingAddressInfo

export type ShippingAddressInfo = ShippingAddress & {
  shippingAddressId: number
}

export class MemberShippingAddressInfoDTO implements ShippingAddressInfo {
  shippingAddressId: number
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  postCode: string
  addressPrefectureCode: string
  addressCity: string
  addressStreet: string
  addressBuilding: Nullable<Optional<string>>
  phone: string

  constructor(
    shippingAddressId: number,
    familyName: string,
    firstName: string,
    familyNameKana: string,
    firstNameKana: string,
    postCode: string,
    addressPrefectureCode: string,
    addressCity: string,
    addressStreet: string,
    addressBuilding: Nullable<Optional<string>>,
    phone: string,
  ) {
    this.shippingAddressId = shippingAddressId
    this.familyName = familyName
    this.firstName = firstName
    this.familyNameKana = familyNameKana
    this.firstNameKana = firstNameKana
    this.postCode = postCode
    this.addressPrefectureCode = addressPrefectureCode
    this.addressCity = addressCity
    this.addressStreet = addressStreet
    this.addressBuilding = addressBuilding
    this.phone = phone
  }

  static fromJSON = (jsonObject: ShippingAddressInfoJSON): MemberShippingAddressInfoDTO => {
    return new MemberShippingAddressInfoDTO(
      jsonObject.shippingAddressId,
      jsonObject.familyName,
      jsonObject.firstName,
      jsonObject.familyNameKana,
      jsonObject.firstNameKana,
      jsonObject.postCode,
      jsonObject.addressPrefectureCode,
      jsonObject.addressCity,
      jsonObject.addressStreet,
      jsonObject.addressBuilding,
      jsonObject.phone,
    )
  }
}
