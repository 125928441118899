import React from 'react'
import styled from "@emotion/styled";
import {MBLogoImg} from "../Atoms/MBLogos";
import {Typography} from "@mui/material";

export type LoadingPageProps = {
  testId?: string
}

/**
 * Page表示前のデータの読み込み中に表示するPage
 * react読み込み中の表示(index.htmlのid:app-loading)は
 * AppMemberの読み込み完了時に消去しているのでその後、APIデータの取得などでLoading表示を延長したい場合に利用
 */
export const LoadingPage: React.VFC<LoadingPageProps> = (props) => {
  return (
    <LoadingDiv data-testid={props.testId ?? 'loading-page'} >
      <LoadingLoadingDiv>
        <MBLogoImg
          data-testid={'loading-logo'}
          logoImgMaxHeight={'44px'}
          logoImgMaxWidth={'76px'}
          src={'https://membucketprcms.blob.core.windows.net/membucket-auth-page/icons/apple-touch-icon.png'}
          alt={'content-header-log'}
        />
        <Typography>しばらくお待ち下さい。</Typography>
      </LoadingLoadingDiv>
    </LoadingDiv>
  )
}

// index.htmlのid:app-loading に合わせる
const LoadingDiv = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 20% 10%;
`

// index.htmlのid:app-loading-loading に合わせる
const LoadingLoadingDiv = styled.div`
  display: block;
`
