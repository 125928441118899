import {useEffect} from 'react'

// マウント開始時(useEffect)にタイマーを開始し、指定時間経過したらコールバック関数にtrueを与える
// APIなどの処理が長く画面表示が始まらない場合に追加の待ち画面を表示する制御に利用することを想定
// ・指定秒数を検知したい各画面のuseState(set)を引数に指定してください
export const countWaitTime = (setWaitTimer: (status: boolean) => void): void => {
  useEffect(() => {
    // 待ち表示のため指定秒数を経過を把握
    const timer = setTimeout(() => {
      setWaitTimer(true)
    }, 3_000)
    // アンマウントで念の為クリアする
    return () => clearTimeout(timer)
  }, [])
}
