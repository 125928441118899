import {Member} from './MemberSelfDTO'

export type MemberVerifyIdentityInfoJSON = Pick<Member, 'mail' | 'familyName' | 'firstName'>

export interface MemberVerifyIdentityInfo {
  mail: string
  name: string
}

export class MemberVerifyIdentityInfoDTO implements MemberVerifyIdentityInfo {
  constructor(
    public mail: string,
    public name: string,
  ) {
  }

  static fromJSON(jsonObject: MemberVerifyIdentityInfoJSON)
    : MemberVerifyIdentityInfoDTO {
    return new MemberVerifyIdentityInfoDTO(
      jsonObject.mail,
      `${jsonObject.familyName} ${jsonObject.firstName}`,
    )
  }
}
