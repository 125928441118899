import {MemberInfoForCompletePage} from '../Props/MemberRegisterPageLocationStates'
import {Signboard, SignboardDTO, SignboardJSON} from './SignboardDTO'
import {ContractInfoWithContractDate} from '../Props/ContractInputInfo'
import {BucketMemberId} from './BucketMemberId'
import {fromJsonBucketMemberIdList} from './BucketRegisterResponseDataDTO'
import {Nullable, Optional} from '../../Common/TypeHelper'

export type MemberRegisterResultJSON =
  Pick<MemberRegisterResult, 'bucketMemberIdList' | 'memberInfo' | 'contractInfo'>
  & {
  signboard: SignboardJSON
  returnPageUrl: Nullable<string>
}

export interface MemberRegisterResult {
  bucketMemberIdList: BucketMemberId[]
  memberInfo: MemberInfoForCompletePage
  signboard: Signboard
  contractInfo: ContractInfoWithContractDate
  returnPageUrl: Optional<string>
}

export class MemberRegisterResultDTO implements MemberRegisterResult {
  bucketMemberIdList: BucketMemberId[]
  memberInfo: MemberInfoForCompletePage
  contractInfo: ContractInfoWithContractDate
  signboard: Signboard
  returnPageUrl: Optional<string>

  constructor(
    bucketMemberIdList: BucketMemberId[],
    memberInfo: MemberInfoForCompletePage,
    contractInfo: ContractInfoWithContractDate,
    signboard: Signboard,
    returnPageUrl: Optional<string>,
  ) {
    this.bucketMemberIdList = bucketMemberIdList
    this.contractInfo = contractInfo
    this.memberInfo = memberInfo
    this.signboard = signboard
    this.returnPageUrl = returnPageUrl
  }

  static fromJSON(jsonObject: MemberRegisterResultJSON): MemberRegisterResultDTO {
    return new MemberRegisterResultDTO(
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
      jsonObject.memberInfo,
      jsonObject.contractInfo,
      {...SignboardDTO.fromJSON(jsonObject.signboard)},
      jsonObject.returnPageUrl !== null ? jsonObject.returnPageUrl : undefined,
    )
  }
}
