export type MBAdminErrorTypes =
  | 'SystemError'
  | string

export class MBAdminError extends Error {
  public errorMessageList?: string[]

  constructor(
    message?: MBAdminErrorTypes | string,
    errorMessageList?: string[],
  ) {
    super(message)
    this.name = new.target.name
    this.errorMessageList = errorMessageList
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
