import {Signboard, SignboardDTO, SignboardJSON} from './SignboardDTO'
import {PaymentMethod, PaymentMethodDTO, PaymentMethodJSON} from './PaymentMethodDTO'

export interface InvitationInfoJSON {
  mail: string,
  signboardInfo: SignboardJSON
  paymentMethodList: PaymentMethodJSON[]
}

export interface InvitationInfo {
  mail: string,
  signboard: Signboard
  paymentMethodList: PaymentMethod[]
}

export class InvitationInfoDTO implements InvitationInfo {
  mail: string
  signboard: Signboard
  paymentMethodList: PaymentMethod[]


  constructor(mail: string, signboard: Signboard, paymentMethodList: PaymentMethod[]) {
    this.mail = mail
    this.signboard = signboard
    this.paymentMethodList = paymentMethodList
  }

  static fromJSON(jsonObject: InvitationInfoJSON): InvitationInfoDTO {
    return new InvitationInfoDTO(
      jsonObject.mail,
      {...SignboardDTO.fromJSON(jsonObject.signboardInfo)},
      jsonObject.paymentMethodList.map(item => ({...PaymentMethodDTO.fromJSON(item)})),
    )
  }
}
