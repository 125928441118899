import {Nullable, Optional} from '../../Common/TypeHelper'
import {MembersBucketDTO, MembersBucketJSON} from './MembersBucketDTO'

export interface Member {
  mail: string
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  gender: Nullable<string>
  birthday: Nullable<string>
  postCode: string
  addressPrefectureCode: string
  addressCity: string
  addressStreet: string
  addressBuilding: string
  phoneMobile: string
  phoneTel: string
}

export interface AgreedInfo {
  agreedToAllAgreements: boolean
  agreeSiteUrl: Optional<string>
}

export interface MemberSelfJSON {
  agreedInfo: AgreedInfo
  personalInfo: Member
  bucketList: MembersBucketJSON[]
}

export class MemberSelfDTO {
  constructor(
    public agreedInfo: AgreedInfo,
    public member: Member,
    public bucketList: MembersBucketDTO[],
  ) {
  }

  static fromJSON(jsonObject: MemberSelfJSON): MemberSelfDTO {
    return new MemberSelfDTO(
      jsonObject.agreedInfo,
      jsonObject.personalInfo,
      jsonObject.bucketList.map(json => MembersBucketDTO.fromJSON(json)),
    )
  }
}
